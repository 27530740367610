import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  ChakraProvider,
  extendTheme,
  Textarea,
  VStack,
  FormControl,
  FormLabel,
  Text,
  FormErrorMessage,
  Input,
  Stack,
  useToast,
  Radio,
  RadioGroup,
  Checkbox,
  CheckboxGroup,
  useColorModeValue,
} from "@chakra-ui/react";
import { FaComments, FaTimes } from "react-icons/fa";
import $ from "jquery";
import axios from "axios";
import ReqDemoModal from "../components/reqDemo";

// Define custom Chakra UI theme
const theme = extendTheme({
  fonts: {
    body: "Arial, sans-serif",
  },
});

const ChatBot = () => {
  const chatRef = useRef(null);
  const [chatHistory, setChatHistory] = useState([]);
  const [chatOpen, setChatOpen] = useState(false);
  const [inputDisabled, setInputDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [jobDetails, setJobDetails] = useState("");
  const [recruiterDetails, setRecruiterDetails] = useState("");
  const [otherDetails, setOtherDetails] = useState("");
  const toast = useToast();
  const [isReqDemoModalOpen, setIsReqDemoModalOpen] = useState(false);

  useEffect(() => {
    // Initialize jQuery code here
    $(chatRef.current)
      .find("#submit")
      .click(function () {
        const question = $(chatRef.current).find("#question").val();
        handleQuestion(question);
      });
  }, []);

  const handleQuestion = (question) => {
    // Disable the input field and submit button
    setInputDisabled(true);

    // Sample question-answer pairs
    const qaPairs = [
      {
        question: "What is your name?",
        answer: "My name is ChatBot. How can I assist you?",
      },
      {
        question: "What services do you offer?",
        answer:
          "I can provide information about our products, assist with account-related queries, and help with troubleshooting.",
      },
      {
        question: "How can I contact customer support?",
        answer:
          "You can reach our customer support team by calling our toll-free number or sending an email to support@example.com.",
      },
      {
        question: "Do you offer refunds?",
        answer:
          "Yes, we have a refund policy in place. Please provide more details about your purchase so that I can assist you further.",
      },
      {
        question: "Can you help with a technical issue?",
        answer:
          "Certainly! Please describe the technical issue you are facing, and I will do my best to assist you.",
      },
    ];

    // Check if the question matches any predefined question-answer pair
    const matchingPair = qaPairs.find(
      (pair) => pair.question.toLowerCase() === question.toLowerCase()
    );

    // Prepare the response
    let response;
    if (matchingPair) {
      response = matchingPair.answer;
    } else {
      response =
        "I'm sorry, but I couldn't understand your question. Please try asking something else.";
    }

    // Add the question and response to the chat history
    const updatedHistory = [
      ...chatHistory,
      { type: "user", content: question },
      { type: "bot", content: response },
    ];
    setChatHistory(updatedHistory);

    // Clear the input field
    $(chatRef.current).find("#question").val("");

    // Enable the input field after a delay to simulate bot response time
    setTimeout(() => {
      setInputDisabled(false);
    }, 1000);
  };

  const toggleChat = () => {
    setChatOpen((prevOpen) => !prevOpen);
  };

  const handleReqDemoModalOpen = () => {
    setIsReqDemoModalOpen(true);
  };

  const handleReqDemoModalClose = () => {
    setIsReqDemoModalOpen(false);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("Email", email);
    formData.append("Mobile", phone);
    formData.append("JobImformation", jobDetails);
    formData.append("RecruiterImformation", recruiterDetails);
    formData.append("OtherImformation", otherDetails);
    formData.append("Message", message);

    try {
      const response = await axios.post(
        "https://script.google.com/macros/s/AKfycbxZRfVVCRY7HDy9gwrA0gBxOcP6_9fykB4yxLq8omrD9cIDse2FZY3DkGYYcZhlqasp/exec",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.result === "success") {
        toast({
          title: "Submitted Successfully!",
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "bottom",
        });
      }

      setEmail("");
      setPhone("");
      setJobDetails("");
      setRecruiterDetails("");
      setOtherDetails("");
      setMessage("");
      setChatOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box position="fixed" bottom="20px" right="20px" zIndex={9999}>
      {/* Chat icon */}
      <Button
        size="md"
        color="white"
        bg="#3B58D7"
        leftIcon={chatOpen ? <FaTimes /> : <FaComments />}
        onClick={toggleChat}
        marginBottom={2}
      >
        {chatOpen ? "Close" : "Chat"}
      </Button>

      {chatOpen && (
        <Box
          ref={chatRef}
          width="300px"
          border="1px solid #ccc"
          borderRadius="md"
          boxShadow="md"
          padding={4}
          position="fixed"
          bottom="70px"
          right="20px"
          bg={useColorModeValue("white", "gray.800")}
        >
          {/* Chatbot title */}
          <Box borderBottom="1px solid #ccc" marginBottom={4}>
            <Box fontWeight="bold" fontSize="xl" textAlign="center">
              Aptofy
            </Box>
          </Box>
          <Text>
            Hi! Let us know how we can help and we’ll respond shortly.
          </Text>

          {/* Chat history */}
          <VStack
            spacing={2}
            align="flex-start"
            maxHeight="300px"
            overflowY="auto"
          >
            {chatHistory.map((chat, index) => (
              <Box
                key={index}
                p={2}
                borderRadius="md"
                backgroundColor={chat.type === "user" ? "gray.100" : "blue.100"}
                alignSelf={chat.type === "user" ? "flex-start" : "flex-end"}
              >
                {chat.content}
              </Box>
            ))}
          </VStack>
          {/* <Card> */}
          {/* Form */}
          <form onSubmit={handleFormSubmit}>
            <VStack spacing={4} align="flex-start" mt={4}>
              <FormControl>
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                  required
                />
              </FormControl>
              <FormControl>
                <FormLabel>Phone</FormLabel>
                <Input
                  type="number"
                  name="phone"
                  maxLength={10}
                  minLength={10}
                  required
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  placeholder="Enter your phone number"
                />
              </FormControl>
              <FormControl>
                <CheckboxGroup colorScheme="green">
                  <Stack spacing={[1, 1]} direction={["column"]}>
                    <Checkbox
                      name="jobDetails"
                      //value={"Looking for Job"}
                      //colorScheme="red"
                      onChange={(e) => {
                        setJobDetails("Looking for Job");
                      }}
                    >
                      Looking for a Job
                    </Checkbox>
                    <Checkbox
                      name="recruiterDetails"
                      checked={recruiterDetails}
                      colorScheme="green"
                      onChange={(e) => {
                        setRecruiterDetails("Looking to Recruit");
                      }}
                    >
                      Looking to Recruit
                    </Checkbox>
                    <Checkbox
                      name="otherDetails"
                      value={"Getting ready for recruitment"}
                      //colorScheme="orange"
                      onChange={(e) => {
                        setOtherDetails("Getting ready for recruitment");
                      }}
                    >
                      Getting ready for recruitment
                    </Checkbox>
                  </Stack>
                </CheckboxGroup>
              </FormControl>

              <FormControl>
                <FormLabel>Your Message</FormLabel>
                <Textarea
                  name="message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Enter your message"
                />
              </FormControl>

              <Stack direction="row" spacing={4}>
                <Button
                  type="submit"
                  color={"white"}
                  bg="#3B58D7"
                  isLoading={isLoading}
                >
                  Submit
                </Button>
                <Button
                  bg="#3B58D7"
                  color={"white"}
                  onClick={handleReqDemoModalOpen}
                >
                  Request Demo
                </Button>
              </Stack>
            </VStack>
          </form>
          {/* </Card> */}
        </Box>
      )}
      <ReqDemoModal
        isOpen={isReqDemoModalOpen}
        onClose={handleReqDemoModalClose}
      />
    </Box>
  );
};

const App = () => {
  return (
    // <ChakraProvider>
    <div>
      {/* Other components */}
      <ChatBot />
    </div>
    //</ChakraProvider>
  );
};

export default App;
